
import device from '~/mixins/device.js'

export default {
  mixins: [device],
  components: {
    OrganicHero: () => import('~/components/Organisms/Organic/Hero/Hero.vue'),
    OrganicHeroCarouselMobile: () => import('~/components/Organisms/Organic/HeroCarouselMobile/HeroCarouselMobile.vue'),
    OrganicModal: () => import('~/components/Molecules/Organic/Modal/Modal.vue'),
    LazyOrganicBigNumbers: () => import('~/components/Organisms/Organic/BigNumbers/BigNumbers.vue'),
    LazyOrganicProducers: () => import('~/components/Organisms/Organic/Producers/Producers.vue'),
    LazyOrganicFormats: () => import('~/components/Organisms/Organic/Formats/Formats.vue'),
    LazyOrganicWhyChoose: () => import('~/components/Organisms/Organic/WhyChooseHotmart/WhyChooseHotmart.vue'),
    LazyOrganicTimeline: () => import('~/components/Organisms/Organic/Timeline/Timeline.vue'),
    LazyOrganicBegin: () => import('~/components/Organisms/Organic/Begin/Begin.vue'),
    LazyOrganicAwards: () => import('~/components/Organisms/Organic/Awards/Awards.vue'),
    LazyOrganicFaq: () => import('~/components/Organisms/Organic/Faq/Faq.vue'),
    LazyOrganicHelp: () => import('~/components/Organisms/Organic/Help/Help.vue'),
    LazyOrganicCreate: () => import('~/components/Organisms/Organic/Create/Create.vue'),
  },

  computed: {
    hasTopStripe() {
      return Boolean(['pt-br'].includes(this.$i18n.locale))
    },

    isVariationSignupEmbed() {
      return Boolean(['pt-br', 'es', 'es-co', 'es-mx'].includes(this.$i18n.locale))
    },

    isVariationCampaign() {
      return null
    },

    locale() {
      return this.$i18n.locale
    },

    route() {
      return this.$route
    },
  },
}
