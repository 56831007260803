import { vwoAsyncCode, vwoPreconnectLink, vwoDisableSanitizers } from './async-client'

function removeVWOBodyClass() {
  if (process.client) {
    document.body.classList.remove('vwo-page')
  }
}

function timeOutRemoveClass() {
  console.log('remove class with timeOut')
  removeVWOBodyClass()
}

function initVWOAntiflicker(document, locale = '') {
  // Fallback to remove Class
  let vwoTimerClass = setTimeout(timeOutRemoveClass, 3000)
  // Verify URL parameter ?v=t
  var urlParams = new URLSearchParams(window.location.search)
  var hasVariantParam = urlParams.has('v')

  if (hasVariantParam) {
    // Imediately remove body class if parameter ?v=t exist
    removeVWOBodyClass(document)
  } else if (window.VWO) {
    VWO.push([
      'onVWOCampaignsLoaded',
      function () {
        console.log('vwo campaigns are loaded and remain time is', vwoTimerClass)
        removeVWOBodyClass()
        clearTimeout(vwoTimerClass)
      },
    ])
  } else {
    //if any condition has be solved
    removeVWOBodyClass()
  }
}

function insertVWOScripts(locale, isBot) {
  const avaliableVWOLanguages = ['es', 'es-es']
  let snippet = {}

  // Verificar se estamos no lado do servidor e se o middleware detectou um bot
  if (avaliableVWOLanguages.includes(locale) && !isBot) {
    snippet = {
      vwoDisableSanitizers,
      vwoAsyncCode,
      vwoPreconnectLink,
    }
  }

  return snippet
}

export { initVWOAntiflicker, insertVWOScripts }
